import React from 'react';
import Link from 'next/link';
import styles from './Breadcrumbs.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';


export default function Breadcrumbs({ items }) {
  return (
    <nav aria-label="breadcrumb" className={styles.breadcrumbNav}>
      <ol className={styles.breadcrumbList}>
        {items.map((item, index) => (
          <li
            key={index}
            className={`${styles.breadcrumbItem} ${
              index === items.length - 1 ? styles.activeItem : ''
            }`}
          >
            {index === 0 && (
              <FontAwesomeIcon icon={faList} style={{ marginRight: '0.5rem', width: '13px', height: '10px' }} />
            )}
            {item.path ? (
              <Link href={item.path} className={styles.breadcrumbLink}>
                {item.label}
              </Link>
            ) : (
              <span>{item.label}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};
