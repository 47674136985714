'use client';

import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs"; 
import React, { useEffect, useState, useContext } from "react";
import styles from "./login.module.css"
import Image from 'next/image';
import { UserContext } from "@/contexts/UserContext";


export default function Login() {

    const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL;
    const BOT_NAME = process.env.NEXT_PUBLIC_BOT_NAME;

    const breadcrumbs = [
        { label: "Главная", path: "/" },
        { label: "Вход" },
      ];
    
    const [token, setToken] = useState("");
    const { userData, setUserData } = useContext(UserContext);
    const [isAuth, setIsAuth] = useState(false);
    
    useEffect(() => {
        getJwtToken();
    }, []);


    const getJwtToken = async () => {
        try {
            const response = await fetch(`${BACKEND_URL}/api/auth/telegram/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
    
            if (!response.ok) {
                throw new Error(`Error handling data from server ${response.status}`);
            }
            const data = await response.json();
            setToken(data);
        } catch(error) {
            console.log(`Error - ${error}`);
        }
    }

    const handleAuth = async () => {

        setTimeout(() => {
            window.open(`https://t.me/${BOT_NAME}?start=${token}`, "_blank");
          }, 0);   
        
        const intervalId = setInterval( async () => {

            try {
                const response = await fetch(`${BACKEND_URL}/api/user/jwt/create/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(token),
                });

                if (!response.ok) {
                    throw new Error(`Error handling data from server ${response.status}`);
                }

                const data = await response.json();
                localStorage.setItem("accessToken", data.jwt_token);
                setIsAuth(true);
                setUserData({
                    user_id: data.user_id,
                    balance: data.balance,
                    quantity: data.quantity,
                    id: data.id,
                    username: data.username,
                    profile_photo: data.profile_photo,
                });
                clearInterval(intervalId);
            } catch(error) {
                console.log(`Error - ${error}`);
            }
        }, 800);
    };

    return(
        <>
        <Breadcrumbs items={breadcrumbs} />
        <h1 className={styles.h1Login}>Вход</h1>
        

        <div className={styles.loginWrapper}>
            <div className={styles.telegramContainer}>
                

                {!isAuth ? (
                    <>
                        <button 
                        onClick={handleAuth} 
                        className={styles.imageContainer}
                        type="button">
                            <Image
                                src="/img/telegram-logo.png"
                                alt="Авторизация телеграмм"
                                width={125} 
                                height={125} 
                            />
                        </button>

                        <p>Для авторизации нажмите на иконку Telegram или перейдите в наше мини-приложение
                            <a href="https://t.me/safezone_bot/start/" className={styles.link} target="_blank" rel='noopener noreferrer'>
                            <br/><br/>👉🏻@safezone_bot👈🏻
                            </a>
                        </p>
                    </>
                ) : (
                    <>
                        <div className={styles.imageContainer}>
                            <Image
                                src="/img/checkmark.svg"
                                alt="Успешный вход"
                                width={100} 
                                height={100} 
                            />
                        </div>

                        <p className={styles.success}>Успешная авторизация</p>
                        <p>Вы успешно авторизировались на сайт! Желаем удачных покупок!</p>
                    </>
                )}

            </div>
        </div>



        </>
    )
}